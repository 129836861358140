import "./AuthenticateScreen.css";

import { Alert, Card, Spin, Typography } from "antd";
import { useEffect, useRef } from "react";

import logoImagePng from "@/static/logo.png";

import { useSignIn } from "./useSignIn";

export function AuthenticateScreen() {
  const googleButtonRef = useRef<HTMLDivElement>(null);
  const { isLoading, lastErrorMessage, renderButton } = useSignIn();

  useEffect(() => {
    if (googleButtonRef.current === null || isLoading) return;
    renderButton(googleButtonRef.current);
  }, [isLoading, googleButtonRef.current]);

  return (
    <div className="authenticate-background">
      <Card
        bordered
        bodyStyle={{ display: "flex", flexDirection: "column", justifyContent: "center", rowGap: 8 }}
        style={{ padding: 12, paddingBottom: 4, width: "unset" }}
      >
        <img src={logoImagePng} style={{ display: "block", margin: "auto" }} />
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          Toegang voor medewerkers
        </Typography.Title>

        {lastErrorMessage !== undefined && <Alert message={lastErrorMessage} type="error" showIcon />}
        {isLoading ? <Spin /> : <div ref={googleButtonRef} />}
      </Card>
    </div>
  );
}
