import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { useAuthentication } from "@/authentication";
import AdminPanel from "@/routes";
import { AuthenticateScreen } from "@/routes/Authenticate";
import Scheduler from "@/routes/Scheduler";

export default function DefibrionApp() {
  const { isAuthenticated } = useAuthentication();

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <Routes>
          <Route element={<Scheduler />} path="/scheduler" />
          <Route element={<AdminPanel />} path="*" />
          {/* Redirect to start screen after logging in */}
          <Route element={<Navigate to="/" />} path="/login" />
        </Routes>
      ) : (
        <Routes>
          <Route element={<AuthenticateScreen />} path="/login" />
          <Route element={<Navigate to="/login" />} path="*" />
        </Routes>
      )}
    </BrowserRouter>
  );
}
