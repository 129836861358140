import { useState } from "react";

import { useAuthentication } from "@/authentication";
import { GOOGLE_OAUTH_CLIENT_ID, GOOGLE_OAUTH_ORGANIZATION } from "@/constants";

export function useSignIn() {
  const authState = useAuthentication();

  const [isLoading, setIsLoading] = useState(false);
  const [lastErrorMessage, setLastErrorMessage] = useState<string>();

  const renderButton = (element: HTMLDivElement) => {
    google.accounts.id.initialize({
      client_id: GOOGLE_OAUTH_CLIENT_ID,
      hd: GOOGLE_OAUTH_ORGANIZATION,
      callback: async function handleCredentialsResponse(response) {
        try {
          setIsLoading(true);
          await authState.loginUser(response.credential);
        } catch (error) {
          setLastErrorMessage((error as Error).message);
        } finally {
          setIsLoading(false);
        }
      },
    });

    google.accounts.id.renderButton(element, {
      theme: "outline",
      size: "large",
      type: "standard",
    });
  };

  return { isLoading, lastErrorMessage, renderButton };
}
